/* General Layout */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header, footer {
  background-color: #003366;
  color: white;
  text-align: center;
  padding: 10px 0;
}
/* Styling for the Company Name Section */
.company-name {
  margin-left: 2.0rem;
  color: white;
  font-weight: bold;
}

button, .button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.hero {
  padding: 12.5rem;
  background-color: #f4f4f4;
  background-image: url('/public/images/hero-bg.jpg');
  background-size: cover;
  background-position: center;
  height: 50vh;
  justify-content: center;
  align-items: center;
  position: relative;
  
}

.para{
  padding: auto;
  background-color: rgb(230, 238, 114);
  border-radius: 10%;
  width: 50%;
}

.para-h4{
  margin-left: 5px;
  margin-top: 10px;
}
.services-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    max-width: 1200px;
}

.team-cards {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.services-overview{
  text-align: center;
}

.card {
  background-color: rgb(144, 169, 252);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  width: 200px;
  text-align: center;
}

.card-image{
  width: 100%;
  height: 150px;
}
form input, form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

footer {
  margin-top: auto;
}


nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

nav ul li {
  margin-right: 20px;
  position: relative;
  font-size: 18px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  display: inline-block;
  transition: background-color 0.3s;
}

nav ul li a:hover {
  background-color: #004080;
}

/* Arrow for Dropdown */
.arrow {
  margin-left: 5px;
  font-size: 12px;
}

/* Dropdown Menu Styles */
nav ul li ul.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #003366;
  list-style-type: none;
  padding: 0;
  min-width: 200px;
  z-index: 1;
}

nav ul li:hover ul.dropdown-menu {
  display: block;
}

nav ul li ul.dropdown-menu li {
  display: block;
}

nav ul li ul.dropdown-menu li a {
  padding: 10px 20px;
  white-space: nowrap;
  display: block;
  color: white;
}

nav ul li ul.dropdown-menu li a:hover {
  background-color: #004080;
}

.whatsapp-link {
  background-color: #25D366; /* WhatsApp Green */
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.whatsapp-link:hover {
  background-color: #20B858;
}

/* Call Button */
.call-button {
  margin-left: auto;
}

.call-btn {
  background-color: #3f95e6; 
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.call-btn:hover {
  background-color: #3c8dda;
}


.company-header{
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* background-color: white; */
  top: 0;
  padding: 10px;
  margin-bottom: 10px;
} 


.title-header{
  margin-bottom: 0;
  font-size: 3.5rem;
}

.learn-more-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007BFF;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.learn-more-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-cards, .team-cards {
      flex-direction: column;
      align-items: center;
  }

  .card {
      margin-bottom: 20px;
      width: 80%;
  }

  nav ul {
    flex-direction: column;
    text-align: center;
}

nav ul li {
    margin-bottom: 10px;
    margin-right: 0;
}

nav ul li ul.dropdown-menu {
    position: static;
}

.hero{
  padding: 20px;
  width: 90%;
  height: 80vh;
}

.company-header{
  flex-direction: column;
}

.learn-more-button {
  font-size: 0.85rem;
  padding: 6px 12px;
}

}
