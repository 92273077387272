.contact-section {
    background-image: url("/public/images/contact-bg.jpg"); /* Background image path */
    background-size: cover;    /* Ensure the image covers the entire section */
    background-position: center; /* Center the background image */
    height: 100vh;             /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.form-container {
    background-color: rgba(255, 255, 255, 0.8);  /* Semi-transparent white background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    height: 600px;
    width: 100%;
    text-align: center;
}

.form-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.form-group textarea {
    resize: none;
    height: 100px;
}

button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-icons a {
    margin: 0 15px;
    color: #333; /* Customize the icon color */
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #0077b5; /* Customize hover color, e.g., LinkedIn blue */
}

