/* Clock.css */
.clock-container {
    display: flex;
    justify-content: left;
    align-items: left;
  }
  
  .clock {
    font-size: 2.0rem;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  