.team-section {
    padding: 60px 20px;
    text-align: center;
    background-color: #f4f7fa;
}

.team-intro {
    margin-bottom: 50px;
}

.team-intro h2 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    color: #2c3e50;
    font-weight: bold;
    text-transform: uppercase;
}

.team-intro p {
    font-size: 1.2rem;
    color: #7f8c8d;
    max-width: 800px;
    margin: 0 auto;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    justify-items: center;
}

.team-member {
    background-color: #003366;
    color: white;
    /* padding: 20px; */
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.team-member img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 0;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.team-member:hover img {
    transform: scale(1.05);
}

.member-info h3 {
    font-size: 1.8rem;
    color: #2980b9;
    margin-bottom: 10px;
}

.member-info p {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 10px;
}

.member-info p:last-child {
    font-size: 0.9rem;
    color: #95a5a6;
}

@media (max-width: 768px) {
    .team-member img {
        height: 200px;
    }

    .team-intro h2 {
        font-size: 2rem;
    }

    .team-intro p {
        font-size: 1rem;
    }
}
