.service-page {
    padding: 50px 20px;
    background-color: #f4f4f4;
    min-height: 80vh;
  }
  
  .service-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .service-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .service-content {
    max-width: 900px;
    margin: 0 auto;
    font-size: 1.1rem;
    color: #555;
  }
  
  .service-content p {
    margin-bottom: 20px;
  }
  
  .service-content ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .service-content ul li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  /* Responsive Adjustments for Tablets */
  @media (max-width: 768px) {
    .service-header h1 {
      font-size: 2rem;
    }
    
    .service-content {
      padding: 0 15px;
      font-size: 1rem;
    }
    
    .service-content ul li {
      font-size: 0.9rem;
    }
  }
  
  /* Responsive Adjustments for Mobile Devices */
  @media (max-width: 576px) {
    .service-header h1 {
      font-size: 1.8rem;
    }
    
    .service-content {
      padding: 0 10px;
      font-size: 0.95rem;
    }
    
    .service-content ul li {
      font-size: 0.85rem;
    }
  }
  