.services-page {
    padding: 50px;
    background-color: #f4f4f4;
}

.services-header {
    text-align: center;
    margin-bottom: 50px;
}

.services-header h1 {
    font-size: 2.5rem;
    color: #fffdfd;
}

.services-header p {
    font-size: 1.2rem;
    color: #ece6e6;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin: 0 auto;
    max-width: 1200px;
}

.service-card {
    background-color: rgb(144, 169, 252);
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card img.service-icon {
    max-width: 80px;
    margin-bottom: 20px;
}

.service-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.service-card p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.learn-more-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007BFF;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.learn-more-button:hover {
    background-color: #0056b3;
}

/* Responsive styling */
@media (max-width: 768px) {
    .services-page {
        padding: 30px;
    }

    .services-header h1 {
        font-size: 2rem;
    }

    .services-header p {
        font-size: 1rem;
    }

    .services-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 15px;
    }

    .service-card h3 {
        font-size: 1.25rem;
    }

    .service-card p {
        font-size: 0.9rem;
    }

    .learn-more-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
}

@media (max-width: 480px) {
    .services-page {
        padding: 20px;
    }

    .services-header h1 {
        font-size: 1.8rem;
    }

    .services-header p {
        font-size: 0.9rem;
    }

    .services-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .service-card {
        padding: 15px;
    }

    .service-card h3 {
        font-size: 1.2rem;
    }

    .service-card p {
        font-size: 0.85rem;
    }

    .learn-more-button {
        font-size: 0.85rem;
        padding: 6px 12px;
    }
}