/* Styling for About Us Section */
.about-section {
    padding: 60px 20px;
    background-color: #f8f9fa;
    color: #333;
    text-align: center;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.about-section h2 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #007bff;
}

.about-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.about-text {
    flex: 1;
    padding: 20px;
    text-align: left;
}

.about-text h3 {
    font-size: 28px;
    margin-bottom: 15px;
    color: #0056b3;
}

.about-text p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.about-image {
    flex: 1;
    text-align: center;
}

.about-image img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Core Values Section */
.values-section {
    margin-top: 60px;
}

.values-section h3 {
    font-size: 32px;
    margin-bottom: 30px;
    color: #007bff;
}

.values {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.value-item {
    flex: 1 1 30%;
    max-width: 30%;
    background-color: rgb(248, 246, 94);
    padding: 30px;
    margin: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.value-item:hover {
    transform: translateY(-10px);
}

.value-item i {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 15px;
}

.value-item h4 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #333;
}

.value-item p {
    font-size: 16px;
    line-height: 1.6;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
    }

    .about-image {
        margin-top: 20px;
    }

    .values {
        flex-direction: column;
    }

    .value-item {
        max-width: 100%;
    }
}
